<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0545 2.94531C6.53445 2.94531 2.06445 7.42531 2.06445 12.9453C2.06445 18.4653 6.53445 22.9453 12.0545 22.9453C17.5845 22.9453 22.0645 18.4653 22.0645 12.9453C22.0645 7.42531 17.5845 2.94531 12.0545 2.94531ZM12.0645 20.9453C7.64445 20.9453 4.06445 17.3653 4.06445 12.9453C4.06445 8.52531 7.64445 4.94531 12.0645 4.94531C16.4845 4.94531 20.0645 8.52531 20.0645 12.9453C20.0645 17.3653 16.4845 20.9453 12.0645 20.9453ZM12.5645 7.94531H11.0645V13.9453L16.3145 17.0953L17.0645 15.8653L12.5645 13.1953V7.94531Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcClock',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
